/* eslint-disable react/jsx-indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-sequences */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import '@ingka/table/style.scss';
import '@ingka/commercial-message/style.scss';
import CommercialMessage from '@ingka/commercial-message';
import '../../../App.scss';
import './Dueindatatable.scss';
import { useNavigate } from 'react-router-dom';
import SitsContext from '../../../context/SitsContext';
import { retreivingParentCountryName, sourceLocaleName, spaceAfterhundreds } from '../../../constants';
import { selectedPage } from '../../../common-components/header/Header';
import semiAutomationCountries from '../../../countriesData/semiAutomaticCountries.json';
import Checkbox from '@ingka/checkbox';
import Loadingcomponent from '../../../common-components/loader/Loadingcomponent';
import { selectedCountryConfig, selectedPopupValue } from '../../../App';
import enabledObjects from '../../../enabledObjects/enableObjects.json';

let globalCheckedFlag = false;
export default function Dueindatatable(props) {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const [multipleTextids, setMultipleTextids] = useState([]);
  const [selectAll, setselectedAll] = useState(false);
  const [dueintabletextdata, setDueInTableTextData] = useState([]);
  const [remainingCheckedTextsFlag, setUpdateRemainingCheckedTexts] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [remainingCheckedTextsData, setRemainingCheckedTextsData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loadingText, setLoadingText] = useState('Loading your results');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      if (sitsContext.numberOfReleasedTexts !== 0) {
        const multipleidSelected = sitsContext.selectedtextsforbulkedit.filter((id) => !sitsContext.numberOfReleasedTexts.includes(id.textid.split('-')[0]));
        setMultipleTextids(multipleidSelected);
        sitsContext.updateSelectedtextsforbulkedit(multipleidSelected);
      } else if (sitsContext.numberOfReleasedTexts === 0) {
        setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      }
    }
  }, []);

  useEffect(() => {
    if (props.loadData !== 'newData') {
      if (props.dueinTableData.length !== 0 && sitsContext.startpageDueinData.length === 0
        && sitsContext.editorPageStatusButton !== 'loadNewData'
      ) {
        setShowLoader(true);
        setDueInTableTextData([...props.dueinTableData]);
        setShowLoader(false);
      } else if (sitsContext.dueInTableDetails !== 0
        && sitsContext.editorPageStatusButton !== 'loadNewData') {
        setShowLoader(true);
        const updatedLocalDueinTableData = props.dueinTableData.map((updatedTextData) => {
          const newLocalData = sitsContext.dueInTableDetails.find((detail) => (updatedTextData.textIds.includes(detail.textIds[0])
        && updatedTextData.textIds.length === detail.textIds.length));
          return {
            ...updatedTextData,
            ...newLocalData,
          };
        });
        setDueInTableTextData([...updatedLocalDueinTableData]);
        setShowLoader(false);
      }
    }
  }, [props.dueinTableData]);

  useEffect(() => {
    if (props.loadData === 'newData' && (props.dueinTableData !== 0)) {
      setShowLoader(true);
      const updatedTableData = props.dueinTableData.map((textInfo) => {
        const isTextSelected = sitsContext.selectedtextsforbulkedit.some(
          (selectedText) => textInfo.textIds.includes(selectedText.textid),
        );
        if (isTextSelected) {
          return {
            ...textInfo,
            textsRowChecked: true,
          };
        }
        return {
          ...textInfo,
          textsRowChecked: false,
        };
      });
      setDueInTableTextData(updatedTableData);
      setShowLoader(false);
    }
  }, [props.dueinTableData, props.loadData]);

  useEffect(() => {
    if (dueintabletextdata.length !== 0) {
      const updatedDueInData = [...dueintabletextdata];
      sitsContext.updateStartpageDueinData(true, updatedDueInData);
    }
  }, [dueintabletextdata]);

  useEffect(() => {
    if (dueintabletextdata.length !== 0) {
      const updatedDueInData = [...dueintabletextdata];
      sitsContext.updateDueInTableDetails(updatedDueInData);
    }
  }, [dueintabletextdata]);

  useEffect(() => {
    if (props.dueinTableData.length !== 0) {
      const selectedAlltextids = [];
      props.dueinTableData.map((number) => (
        // eslint-disable-next-line array-callback-return
        number.textIds.map((ids) => {
          const individualTextidData = {
            textid: ids,
            source_locale_code: 'en-ZZ',
            target_locale_code: selectedPopupValue.selectedlangvalue,
            object_type_short: number.objShortName,
            isParentEnabled: true,
          };
          selectedAlltextids.push(individualTextidData);
        })
      ));
      sitsContext.updateDueInDataAllTextsInfo(selectedAlltextids);
    }
  }, [props.dueinTableData]);

  useEffect(() => {
    if (remainingCheckedTextsFlag) {
      const localRemainingTexts = remainingCheckedTextsData.map((dueinindtabledata) => ({
        ...dueinindtabledata,
        textsRowChecked: globalCheckedFlag,
      }));
      setUpdateRemainingCheckedTexts(false);
      setDueInTableTextData([...dueintabletextdata, ...localRemainingTexts]);
    }
  }, [remainingCheckedTextsFlag, remainingCheckedTextsData]);

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      if (sitsContext.selectedtextsforbulkedit.length === props.dueInTotalCount) {
        setselectedAll(true);
      }
    } else {
      setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      setselectedAll(false);
    }
  }, [sitsContext.selectedtextsforbulkedit]);

  const updateRemainingCheckedTextsHandler = (dueinTextData) => {
    const updatedRemainingTextData = [...dueinTextData.slice(1000, (dueinTextData.length))];
    setUpdateRemainingCheckedTexts(true);
    setRemainingCheckedTextsData([...updatedRemainingTextData]);
  };

  // eslint-disable-next-line no-unused-vars
  const selectAllIds = (e) => {
    props.updateLoadData('previousData');
    let selectedAlltextids = [];
    if (e.target.checked === true) {
      (props.dueinTableData).map((number) => (
        // eslint-disable-next-line array-callback-return
        number.textIds.map((ids) => {
          const individualTextidData = {
            textid: ids,
            source_locale_code: 'en-ZZ',
            target_locale_code: selectedPopupValue.selectedlangvalue,
            object_type_short: number.objShortName,
            isParentEnabled: true,
          };
          selectedAlltextids.push(individualTextidData);
        })
      ));
      setselectedAll(true);
    } else if (e.target.checked === false) {
      // eslint-disable-next-line no-const-assign
      selectedAlltextids = [];
      setselectedAll(false);
    }
    setMultipleTextids(selectedAlltextids);
    sitsContext.updateSelectedtextsforbulkedit(selectedAlltextids);
  };

  const checkedAllHandler = (checkedFlag) => {
    if (checkedFlag.target.checked === false) {
      props.updateUnCheckedAll(true);
    }
    globalCheckedFlag = checkedFlag.target.checked;
    if (dueintabletextdata.length > 1000) {
      const localDueInTableRemainingData = [...dueintabletextdata];
      const localDueInTableData = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 1000; i++) {
        const localDueInIndividualData = {
          ...dueintabletextdata[i],
          textsRowChecked: checkedFlag.target.checked,
        };
        localDueInTableData.push(localDueInIndividualData);
      }
      setDueInTableTextData([...localDueInTableData]);
      updateRemainingCheckedTextsHandler(localDueInTableRemainingData);
    } else {
      setDueInTableTextData(dueintabletextdata.map((dueinindtabledata) => ({
        ...dueinindtabledata,
        textsRowChecked: checkedFlag.target.checked,
      })));
    }
    sitsContext.updateCheckedAllFlag(checkedFlag.target.checked);
    setselectedAll(checkedFlag.target.checked);
    selectAllIds(checkedFlag);
  };

  useEffect(() => {
    if ((sitsContext.checkedAllFlag === false)
    && (selectAll === true) && !props.deselectAllClicked) {
      const checkedFlag = {
        target: {
          checked: false,
        },
      };
      checkedAllHandler(checkedFlag);
      setselectedAll(false);
    }
  }, [sitsContext.checkedAllFlag, sitsContext.selectedtextsforbulkedit]);

  // Below useeffect gets executed when deselect button is clicked on the bottom bulk edit banner
  useEffect(() => {
    if (props.deselectAllClicked && (sitsContext.checkedAllFlag === false)) {
      const checkedFlag = {
        target: {
          checked: false,
        },
      };
      checkedAllHandler(checkedFlag);
      setselectedAll(false);
      props.updateDeselectFlag(false);
    }
  }, [props.deselectAllClicked, sitsContext.checkedAllFlag]);

  // function for the Bulk Edit redirection from the start page to the editor page
  // eslint-disable-next-line no-unused-vars
  const multipleselect = (textids, objShortName, e) => {
    props.updateLoadData('previousData');
    setselectedAll(false);
    let selectedTexts = [];
    const textidCheckedvalue = e.target.checked;

    const textIdData = textids.map((number) => {
      const individualTextidData = {
        textid: number,
        object_type_short: objShortName,
      };
      return individualTextidData;
    });

    if (textidCheckedvalue === true) {
      selectedTexts = multipleTextids.concat(textIdData);
    } else {
      selectedTexts = multipleTextids.filter((id) => !textids.includes(id.textid));
    }

    const routingData = selectedTexts.map((data) => {
      const routingIndividual = {
        textid: data.textid,
        source_locale_code: 'en-ZZ',
        target_locale_code: selectedPopupValue.selectedlangvalue,
        object_type_short: data.object_type_short,
        isParentEnabled: true,
      };
      return routingIndividual;
    });
    setMultipleTextids(routingData);
    if (routingData.length === 0) {
      sitsContext.updateCheckedAllFlag(false);
      props.updateUnCheckedAll(true);
    }
    sitsContext.updateSelectedtextsforbulkedit(routingData);
  };

  const checkedUncheckedSingleRowHandler = (textIdsInfo, objShortName, checkedFlag) => {
    sitsContext.updateCheckedAllFlag(false);
    if (dueintabletextdata.length > 1500) {
      let indexOfText = null;
      let checkedUncheckedTextData;

      let startIndex = 0;
      let endIndex = dueintabletextdata.length - 1;
      while (startIndex <= endIndex) {
        if (dueintabletextdata[startIndex].textIds.includes(textIdsInfo[0])) {
          indexOfText = startIndex;
          // eslint-disable-next-line no-unused-vars
          checkedUncheckedTextData = {
            ...dueintabletextdata[startIndex],
            textsRowChecked: checkedFlag.target.checked,
          };
          break;
        }

        if (dueintabletextdata[endIndex].textIds.includes(textIdsInfo[0])) {
          // eslint-disable-next-line no-unused-vars
          indexOfText = endIndex;
          // eslint-disable-next-line no-unused-vars
          checkedUncheckedTextData = {
            ...dueintabletextdata[endIndex],
            textsRowChecked: checkedFlag.target.checked,
          };
          break;
        }
        startIndex++;
        endIndex--;
      }
      const updatedDueInData = [
        ...dueintabletextdata.slice(0, (indexOfText)),
        checkedUncheckedTextData,
        ...dueintabletextdata.slice((indexOfText + 1), (dueintabletextdata.length)),
      ];
      setDueInTableTextData(updatedDueInData);
    } else {
      setDueInTableTextData(dueintabletextdata.map((dueinindtabledata) => {
        if (dueinindtabledata.textIds.includes(textIdsInfo[0])) {
          return {
            ...dueinindtabledata,
            textsRowChecked: checkedFlag.target.checked,
          };
        }
        return { ...dueinindtabledata };
      }));
    }
    multipleselect(textIdsInfo, objShortName, checkedFlag);
  };

  // function for the redirection from the start page to the editor page
  const redirectEditor = (id, objName) => {
    let arrayOFTextIds = [];
    const selectedtext = id.map((data) => {
      const routingIndividual = {
        textid: data.indexOf('-') === -1 ? data : data.split('-')[0],
        source_locale_code: 'en-ZZ',
        target_locale_code: selectedPopupValue.selectedlangvalue,
        object_type_short: objName,
        isParentEnabled: true,
      };
      return routingIndividual;
    });

    const listOfIdsSelected = id.map((data) => {
      const routingIndividual = {
        textId: data.indexOf('-') === -1 ? data : data.split('-')[0],
        objectNameShort: objName,
      };
      if (data.split('-')[1] === 'translated') {
        arrayOFTextIds = arrayOFTextIds.concat(data.indexOf('-') === -1 ? data : data.split('-')[0]);
      }
      return routingIndividual;
    });

    sessionStorage.setItem('funcCalled', 'true');
    navigate('/Editorpage', {
      state: {
        selectedPageTitle: selectedPage,
        selectedTextData: selectedtext,
        sourceLocale: sourceLocaleName,
        targetLocale: sitsContext.selectedLocaleName,
      },
    });
    if (sitsContext.autoSaveHeaderChange !== null) {
      sitsContext.updateAutoSaveHeaderChange(null);
    }
    sitsContext.updateDataOfRelaseTexts(listOfIdsSelected, arrayOFTextIds);
    sitsContext.updateEditorSearchPageLocationData(selectedPage, selectedtext, sourceLocaleName, sitsContext.selectedLocaleName);
    sitsContext.updateFilterDisplayFlag(false);
    sitsContext.updateInitialFilterFlag(false);
    sitsContext.updateNavigateEditToSearchPageFlag(`${selectedPage}`);
  };

  const categoryMoreHandler = (event, textIds) => {
    props.categoryMoreClick(textIds);
  };

  return (
    <div>
      {(showLoader || dueintabletextdata.length === 0)
      && <div><Loadingcomponent loadingText={loadingText} /></div>}
      {(!showLoader && dueintabletextdata.length !== 0) && (
<div>
{props.enableLoad && <div><Loadingcomponent loadingText={loadingText} /></div>}
        <Table fullWidth style={{ overflow: 'hidden', visibility: props.enableLoad === true ? 'hidden' : 'visible' }}>
          <TableHeader sticky>
            <tr className="width_table">
              <th className="dueintable_cell_padding3 checkboxplacement">
                {' '}
                <Checkbox
                  className="checkbox_MarginChange"
                  indeterminate={multipleTextids.length !== 0 && !selectAll}
                  checked={selectAll}
                  // onChange={(e) => { selectAllIds(e); }}
                  onChange={(e) => { checkedAllHandler(e); }}
                />
                {' '}
              </th>
              <th className="text_align_R Num_texts_width dueintable_cell_padding">No. of texts</th>
              <th className="text_align_L objecttype_width dueintable_cell_padding1">Object type</th>
              <th className={`text_align_L ${semiAutomationCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())
                ? 'categories_width_supportInfo' : 'categories_width'
              } dueintable_cell_padding1`}
              >
                Category
              </th>
              <th className="text_align_L due_in_width dueintable_cell_padding">Due in</th>
              <th className="overdue_width"> </th>
              <th className="text_align_R Num_words_width dueintable_cell_padding2">No. of words</th>
              {semiAutomationCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())
              && <th className="text_align_L translation_info_width dueintable_cell_padding4">{selectedCountryConfig.displayName !== undefined ? `Translation (${retreivingParentCountryName(selectedCountryConfig.displayName)})` : '' }</th>}
            </tr>
          </TableHeader>
          {dueintabletextdata.length !== 0
          && (dueintabletextdata).map((tabledata, dataindex) => (
            enabledObjects.objectsInDashboard.includes(tabledata.object_type)
              ? (
                <TableBody key={dataindex} className="td-body-remove-bordercolor">
                  <tr className="hide-background-color-dupl cursor_default_default width_table">
                    <td className="dueintable_cell_padding3 checkboxplacement">
                      <Checkbox
                        className="checkbox_MarginChange"
                    // eslint-disable-next-line react/jsx-boolean-value
                        // checked={selectAll || tabledata.textIds.some((id) => multipleTextids.some((selectedId) => selectedId.textid.split('-')[0] === id.split('-')[0])) === true}
                        checked={tabledata.textsRowChecked}
                        // onChange={(e) => multipleselect(tabledata.textIds, tabledata.objShortName, e)}
                        onChange={(e) => checkedUncheckedSingleRowHandler(tabledata.textIds, tabledata.objShortName, e)}
                      />
                    </td>
                    <td className="text_align_R Num_texts_width dueintable_cell_padding">{spaceAfterhundreds(tabledata.no_of_texts)}</td>
                    <td id="objectType" className="text_align_L objecttype_width dueintable_cell_padding1 Object_type_clickable" onClick={() => { redirectEditor(tabledata.textIds, tabledata.objShortName); }} style={{ textTransform: 'capitalize' }}>{tabledata.object_type}</td>
                    <td
                      id="categories"
                      className={
                        `text_align_L
                        ${semiAutomationCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())
                          ? 'categories_width_supportInfo' : 'categories_width'
                        }
                        dueintable_cell_padding1`
                      }
                    >
                      {tabledata.categoryOverflow === false
                      && (
                        tabledata.categories[0] === 'uncategorised' ? '' : tabledata.categories.join(', ')
                      )}
                      {tabledata.categoryOverflow === true
                      && (
                        <>
                          <span>{tabledata.updatedCategories.join(', ')}</span>
                          <span>{', '}</span>
                          <span
                            className="category_more_count"
                            onClick={(event) => categoryMoreHandler(event, tabledata.textIds)}
                          >
                            {`+${tabledata.moreCategoriesCount} more`}
                          </span>
                        </>
                      )}
                    </td>
                    <td className={tabledata.range === 'overdue' ? 'text_align_L due_in_width dueintable_cell_padding overdue_text_color' : 'text_align_L due_in_width dueintable_cell_padding'}>
                      {tabledata.due_in !== null
                        ? (
                          `${spaceAfterhundreds(tabledata.due_in)
                          } `
                  + `day${(tabledata.due_in > 1 || tabledata.due_in < 0) ? 's' : ''}`
                        )
                        : 'Not applicable' }
                    </td>
                    <td className="overdue_width dueintable_cell_padding">
                      <div style={{ display: tabledata.range === 'overdue' ? 'block' : 'none' }}>
                        <CommercialMessage variant="nlp" message="Overdue" />
                      </div>
                    </td>
                    <td className="text_align_R Num_words_width dueintable_cell_padding2">{spaceAfterhundreds(tabledata.no_of_words)}</td>
                    {semiAutomationCountries.includes(selectedPopupValue.selectedlangvalue.toUpperCase())
              && (
              <td className="text_align_L translation_info_width dueintable_cell_padding4">
                {tabledata.translationCompletionStatus}
              </td>
              )}
                  </tr>
                </TableBody>
              ) : ''
          ))}
        </Table>
</div>
      )}
    </div>
  );
}
