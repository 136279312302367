/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import React, {
  useState, useEffect, useLayoutEffect, useRef, useContext,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import { selectedPage } from '../../../common-components/header/Header';
import SitsContext from '../../../context/SitsContext';
import { spaceAfterhundreds, retreivingParentCountryName } from '../../../constants';
import { selectedCountryConfig, selectedPopupValue } from '../../../App';

let dropdownsupportInformationFiltervalues = [];

function SupportInformationFiltercomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  const [filtername, setFiltername] = useState([]);
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  const [select, setSelect] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const ref = useRef();
  const [initStat, setInitStat] = useState(true);
  const [supportInformationFilter, setSupportInformationFilter] = useState([]);

  useEffect(() => {
    setSupportInformationFilter([
      {
        supportInfo_filter_name: selectedCountryConfig.displayName !== undefined
          ? `Translation(${retreivingParentCountryName(selectedCountryConfig.displayName)}): Completed` : '',
        itemCount: '0',
        supportInfo_filter_value: 'Completed',
      },
      {
        supportInfo_filter_name: selectedCountryConfig.displayName !== undefined
          ? `Translation(${retreivingParentCountryName(selectedCountryConfig.displayName)}): Not completed` : '',
        itemCount: '0',
        supportInfo_filter_value: 'Not completed',
      },
    ]);
  }, [selectedCountryConfig]);

  useEffect(() => {
    if (props.filterarray !== undefined) {
      if (props.filterarray[0] === 'Overdue' && props.filterarray.length === 1) {
        update([], [], [], []);
      } else if (props.filterarray !== null || props.filtervaluearray !== null) {
        update(Checked, filtername, props.filterarray, props.filtervaluearray);
      }
    }
  }, [props.filterarray, props.filtervaluearray]);

  useEffect(() => {
    if (sitsContext.previousStateSupportInformation.length !== 0) {
      const checked = [...sitsContext.previousStateSupportInformationFilterName];
      const Filtername = [...sitsContext.previousStateSupportInformation];
      setChecked(checked);
      setFiltername(Filtername);
      update(checked, Filtername);
      sitsContext.updatePreviousSupportInformationFilter(
        sitsContext.previousStateSupportInformation,
        sitsContext.previousStateSupportInformationFilterName,
      );
    }
  }, []);

  useLayoutEffect(() => {
    if (typeof props.supportInfoCount !== 'undefined') {
      setInitStat(false);
      supportInformationFilter.forEach((supportinformation) => {
        if (supportinformation.itemCount) delete (supportinformation.itemCount);
        props.supportInfoCount.forEach((supportCount) => {
          if (
            supportCount.groupName.toUpperCase()
            === supportinformation.supportInfo_filter_name.split(': ')[1].toUpperCase()
          ) {
            supportinformation.itemCount = supportCount.itemCount;
          }
        });
      });
      dropdownsupportInformationFiltervalues = supportInformationFilter;
    }
  }, [props.supportInfoCount]);

  // Scroll dashboard page to view the support information filter body in the visible region
  useEffect(() => {
    if (isModalOpen) {
      const filterBodyElement = document.getElementById('supportInformationTypeFilterBody');
      if (filterBodyElement !== undefined && (filterBodyElement !== null)) {
        const filterBodyPositionValues = filterBodyElement.getBoundingClientRect();
        if (filterBodyPositionValues.bottom > (window.innerHeight)) {
          filterBodyElement.scrollIntoView(false);
        }
      }
    }
  }, [isModalOpen]);

  // update function is called when there is any change in the search button in the dropdown
  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
  };

  useOnClickOutside(ref, () => {
    setModalOpen(false);
    setSelect(false);
  });

  // update function is called when there is any change in the selected filters
  const handleChange = (e) => {
    sitsContext.updateintialfiltervalue(true);
    const selectedvalue = e.target.value;
    const selectedfilter = e.target.name;
    sitsContext.updateFilterValues(selectedfilter);
    const currentIndex2 = Checked.indexOf(selectedvalue);
    // capturing the index value of target value
    const newChecked = [...Checked]; // the new checked array is appended with previous values
    const currentIndex1 = filtername.indexOf(selectedfilter);
    // capturing the index value of target name
    const newFilterName = [...filtername]; // the filtername array is appended with previous values

    // checking the index is -1 , if the index is -1 the value will be  pushed into the array
    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newFilterName.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newFilterName.splice(currentIndex1, 1);
    } // if the index is not  -1 the value will be spliced from the array
    setChecked(newChecked); // array is updated with new values
    setFiltername(newFilterName); // array is updated with new values
    update(newChecked, newFilterName);
  };

  const update = (
    newChecked,
    newFilterName,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    const filtervalues = {
      selectedfilters:
          cancelledarrayvalue === null || cancelledarrayvalue === undefined
            ? newChecked
            : (newChecked.filter((item) => cancelledarrayvalue.includes(item))),
      selectedfiltersName:
          cancelledarray === null || cancelledarray === undefined
            ? newFilterName
            : newFilterName.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    setNewarray(filtervalues.selectedfiltersName);
    sitsContext.updatePreviousSupportInformationFilter(
      filtervalues.selectedfiltersName,
      filtervalues.selectedfilters,
    );
    sitsContext.updateintialloading(true);

    props.supportInfoFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            disabled={selectedPage === 'Dashboard' ? false : true}
            selected={!!select}
            small
            label={selectedCountryConfig.displayName !== undefined ? `Translation (${retreivingParentCountryName(selectedCountryConfig.displayName)})` : ''}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
      <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
        <div className="dropdowndiv" id="supportInformationTypeFilterBody">
          <div>
            <ActionList className="action-lists supportInfo_dropdown" id="supportInformation_Filter" variant="compact">
              {dropdownsupportInformationFiltervalues.map((supportInfo, index) => (
                <ActionListItem
                  key={index}
                  control="checkbox"
                  controlProps={{
                    value: supportInfo.supportInfo_filter_value,
                    name: supportInfo.supportInfo_filter_name,
                    checked:
                        newarray.includes(supportInfo.supportInfo_filter_name) === true,
                  }}
                  label={supportInfo.supportInfo_filter_name.split(': ')[1]}
                  onChange={handleChange}
                  quantityLabel={
                    supportInfo.itemCount === undefined
                      ? 0 : spaceAfterhundreds(supportInfo.itemCount)
                  }
                  disabled={
                    !!(supportInfo.itemCount === undefined && !initStat)
                  }
                  className={initStat ? 'count-zero' : 'count-list'}
                />
              ))}
            </ActionList>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}
export default SupportInformationFiltercomponent;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
