/* eslint-disable react/no-array-index-key */
import Modal, { Prompt, ModalFooter, ModalHeader } from '@ingka/modal';
import React, { useContext, useState, useEffect } from 'react';
import Button from '@ingka/button';
// eslint-disable-next-line import/no-unresolved
import List from '@ingka/list';
import Text from '@ingka/text';
import SitsContext from '../../context/SitsContext';
import bannerMessage from '../../messages/bannerMessages.json';
import objects from '../../enabledObjects/enableObjects.json';
// eslint-disable-next-line import/no-cycle
import { disabledEditorObjectLocalesInfo } from '../../App';

export default function BannerMessage() {
  const sitsContext = useContext(SitsContext);
  const [objectsInDashboardPage, setObjectsInDashboardPage] = useState(objects.objectsInBanner);
  const [objectsInSearchPage, setObjectsInSearchPage] = useState(objects.objectsInSearch);

  const onclickclose = () => {
    sitsContext.updateEnableBannerMessage(false);
  };

  useEffect(() => {
    if (disabledEditorObjectLocalesInfo.length !== 0) {
      if (disabledEditorObjectLocalesInfo.includes('Product Name')) {
        setObjectsInDashboardPage(objects.objectsInBanner.filter((obj) => obj !== 'Product Name'));
        setObjectsInSearchPage(objects.objectsInSearch.filter((obj) => obj !== 'Product Name'));
      }
    } else {
      setObjectsInDashboardPage(objects.objectsInBanner);
      setObjectsInSearchPage(objects.objectsInSearch);
    }
  }, [disabledEditorObjectLocalesInfo]);

  return (
    <div>
      <Modal
        visible={sitsContext.enableBannerMessage} // Opens / Closes modal
        handleCloseBtn={onclickclose}
      >
        <Prompt
          title={<Text headingSize="s">{bannerMessage.bannerMessage}</Text>} // Prompt's heading
          header={<ModalHeader ariaCloseTxt="Close prompt" />}
          footer={(
            <ModalFooter>
              <Button text="Close" onClick={onclickclose} type="primary" />
            </ModalFooter>
          )}
        >
          <span>
            <b>Dashboard and Editor</b>
            <List ordered>
              {objectsInDashboardPage.map((textobjects, index) => (
                <li key={index}>{textobjects}</li>
              ))}
            </List>
          </span>
          <span>
            <b>Search</b>
            <List ordered>
              {objectsInSearchPage.map((textobjects, index) => (
                <li key={index}>{textobjects}</li>
              ))}
            </List>
          </span>
        </Prompt>
      </Modal>
    </div>
  );
}
