/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unreachable */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState, useLayoutEffect,
} from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import './OtherLanguages.scss';
import SSRIcon from '@ingka/ssr-icon';
import information from '@ingka/ssr-icon/paths/information-circle';
import SitsContext from '../../../../context/SitsContext';
import negativeFlowMsg from '../../../../messages/negativeFlowMessages.json';

let showMoreFlag = false;

export default function OtherLanguages(props) {
  const sitsContext = useContext(SitsContext);
  const [otheLanguagesData, setOtherLanguagesData] = useState([]);
  const [maxCharacterCountAllowed, setMaxCharacterCountAllowed] = useState(0);

  useEffect(() => {
    if (props.editorBottomRightWidth > 0) {
      if (sitsContext.otherLanguagesCount !== 0 && sitsContext.otherLanguagesCount !== null) {
        let calculatedCount;
        const otherLanguagesContainer = document.getElementById('otherlanguagesParent');
        if (otherLanguagesContainer !== undefined) {
        // 32 is margin
          const rightColumnWidth = (77 / 100) * (otherLanguagesContainer.offsetWidth - 32);
          console.log(rightColumnWidth);
          const maximumCharacterCountAllowed = Math.round(((rightColumnWidth) / (12))); // Font size - 12px
          // eslint-disable-next-line no-unused-vars
          calculatedCount = Math.round((window.innerWidth / 1240) * maximumCharacterCountAllowed);
          setMaxCharacterCountAllowed(calculatedCount);
        }
        console.log(calculatedCount);
        // eslint-disable-next-line no-unused-vars
        const otherLanguagesLocalData = otheLanguagesData.map(
          (otherLanguageText) => {
            // eslint-disable-next-line array-callback-return, consistent-return
            const localOtherLanguageTranslation = otherLanguageText.translation.map(
              (otherLanguageTranslation, translationIndex) => {
                if (translationIndex > 0) {
                  if (showMoreFlag === false) {
                    if (otherLanguageTranslation.value.length > calculatedCount) {
                      showMoreFlag = true;
                      return {
                        ...otherLanguageTranslation,
                        displayShowMore: true,
                        hideText: false,
                      };
                    } else {
                      return {
                        ...otherLanguageTranslation,
                        displayShowMore: false,
                        hideText: false,
                      };
                    }
                  } else if (showMoreFlag === true) {
                    return {
                      ...otherLanguageTranslation,
                      hideText: true,
                      displayShowMore: false,
                    };
                  }
                } else {
                  return {
                    ...otherLanguageTranslation,
                  };
                }
              },
            );
            showMoreFlag = false;
            return {
              ...otherLanguageText,
              // id: otherLanguageIndex,
              // showMoreClicked: false,
              translation: [...localOtherLanguageTranslation],
            };
          },
        );
        setOtherLanguagesData(otherLanguagesLocalData);
      }
    }
  }, [props.editorBottomRightWidth]);

  useLayoutEffect(() => {
    const editorBottomRightHeaderSectionElement = document.getElementById('editorBottomRightHeaderSection');
    if (props.selectedSegment.confirmButtonClicked === false) {
      if ((sitsContext.editorBottomSectionHeight !== 0)
      && (editorBottomRightHeaderSectionElement.offsetHeight !== 0)) {
        const matchesContainerElement = document.getElementById('otherlanguagesParent');
        const calculatedHeight = sitsContext.editorBottomSectionHeight
        - editorBottomRightHeaderSectionElement.offsetHeight - 1;
        const stylesMatchesContainer = window.getComputedStyle(matchesContainerElement);
        const matchesContainerMarginTop = parseFloat(stylesMatchesContainer.marginTop);
        matchesContainerElement.style.height = '5px';
        matchesContainerElement.style.height = `${calculatedHeight - matchesContainerMarginTop}px`;
      }
    }
  }, [sitsContext.editorBottomSectionHeight]);

  useEffect(() => {
    if (sitsContext.otherLanguagesCount !== 0 && sitsContext.otherLanguagesCount !== null) {
      let calculatedCount;
      const otherLanguagesContainer = document.getElementById('otherlanguagesParent');
      if (otherLanguagesContainer !== undefined) {
      // 32 is margin
        const rightColumnWidth = (77 / 100) * (otherLanguagesContainer.offsetWidth - 32);
        const maximumCharacterCountAllowed = Math.round(((rightColumnWidth) / (12))); // 12 is the font size - 12px
        // eslint-disable-next-line no-unused-vars
        calculatedCount = Math.round((window.innerWidth / 1240) * maximumCharacterCountAllowed);
        setMaxCharacterCountAllowed(calculatedCount);
      }
      // eslint-disable-next-line no-unused-vars
      const otherLanguagesLocalData = sitsContext.otherLanguagesInfo.map(
        (otherLanguageText, otherLanguageIndex) => {
          // eslint-disable-next-line array-callback-return, consistent-return
          const localOtherLanguageTranslation = otherLanguageText.translation.map(
            (otherLanguageTranslation, translationIndex) => {
              if (translationIndex > 0) {
                if (showMoreFlag === false) {
                  if (otherLanguageTranslation.value.length > calculatedCount) {
                    showMoreFlag = true;
                    return {
                      ...otherLanguageTranslation,
                      displayShowMore: true,
                      hideText: false,
                    };
                  } else {
                    return {
                      ...otherLanguageTranslation,
                      displayShowMore: false,
                      hideText: false,
                    };
                  }
                } else if (showMoreFlag === true) {
                  return {
                    ...otherLanguageTranslation,
                    hideText: true,
                    displayShowMore: false,
                  };
                }
              } else {
                return {
                  ...otherLanguageTranslation,
                };
              }
            },
          );
          showMoreFlag = false;
          return {
            ...otherLanguageText,
            id: otherLanguageIndex,
            showMoreClicked: false,
            translation: [...localOtherLanguageTranslation],
          };
        },
      );
      setOtherLanguagesData(otherLanguagesLocalData);
    } else {
      setOtherLanguagesData([]);
    }
  }, [sitsContext.otherLanguagesCount, sitsContext.otherLanguagesInfo]);

  const showMoreLessClickHandler = (showMoreLessFlag, selectedId) => {
    const otherLanguagesLocalData = otheLanguagesData.map(
      (otherLanguageText) => {
        if (otherLanguageText.id === selectedId) {
          return {
            ...otherLanguageText,
            showMoreClicked: showMoreLessFlag,
          };
        }
        return {
          ...otherLanguageText,
        };
      },
    );
    setOtherLanguagesData(otherLanguagesLocalData);
  };

  return (
    <div className="otherLanguagesContainer" id="otherlanguagesParent">
      {otheLanguagesData.length !== 0 ? (
        <div>
          <Table fullWidth>
            <TableHeader sticky>
              <tr>
                <th>Language</th>
                <th>Text</th>
              </tr>
            </TableHeader>

            <TableBody className="other_languages_table_body">
              {(otheLanguagesData.length !== 0 && otheLanguagesData.length !== undefined)
      && otheLanguagesData.map((languagesdata, infoIndex) => (
        <tr key={infoIndex}>
          <td className="language_width">{languagesdata.localeName}</td>
          <td className="text_width">
            {languagesdata.translation.map((translationsData, dataIndex) => (
              <>
                {
                dataIndex === 0 && (
                  <>
                    <span key={dataIndex}>
                      {`${translationsData.value} `}
                      {' '}
                    </span>
                    <br />
                  </>
                )
                }
                {
                dataIndex > 0 && (
                  <>
                    {
                    (languagesdata.showMoreClicked === true) && (
                      <>
                        <span key={dataIndex}>
                          {`${translationsData.value} `}
                          {' '}
                        </span>
                        {(languagesdata.translation.length === (dataIndex + 1)) && (
                        <span
                          className="other_languages_show_more_less_alignment"
                          onClick={() => {
                            showMoreLessClickHandler(
                              false,
                              languagesdata.id,
                            );
                          }}
                        >
                          show less
                        </span>
                        )}
                        <br />
                      </>
                    )
                    }
                    {
                    (languagesdata.showMoreClicked === false && translationsData.displayShowMore === true
                      && translationsData.hideText === false) && (
                      <>
                        <span key={dataIndex}>
                          {`${translationsData.value.substring(0, (maxCharacterCountAllowed - 10))} `}
                          ...
                          {' '}
                        </span>
                        <span
                          className="other_languages_show_more_less_alignment"
                          onClick={() => {
                            showMoreLessClickHandler(
                              true,
                              languagesdata.id,
                            );
                          }}
                        >
                          show more
                        </span>
                        <br />
                      </>
                    )
                    }
                    {
                    (languagesdata.showMoreClicked === false && translationsData.displayShowMore === false
                      && translationsData.hideText === false) && (
                      <>
                        <span key={dataIndex}>
                          {`${translationsData.value} `}
                          {' '}
                        </span>
                        <br />
                      </>
                    )
                    }
                  </>
                )
                }
              </>
            ))}
          </td>
        </tr>
      ))}
            </TableBody>

          </Table>
        </div>
      ) : (
        <div className="no_results_bottomtab">
          <div>
            <SSRIcon className="information_circle_color" paths={information} />
          </div>
          <div className="no_results_text_styling">{negativeFlowMsg.noOtherLanguages}</div>
        </div>
      )}
    </div>
  );
}
