/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import React, {
  useState, useEffect, useRef, useContext, useLayoutEffect,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import Button from '@ingka/button';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import { SearchbarData } from '../searchBar/SearchBarComponent';
import { retrieveStatus, gStatusTypeValues, gStatusTypeValuesError } from '../../../api/Apirequests';
import ErrorHandler from '../../../common-components/errorhandler/ErrorHandler';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';
import { spaceAfterhundreds } from '../../../constants';

let dropdownStatusvalues = [];

function Statuscomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  const [filtername, setFiltername] = useState([]);
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  const [select, setSelect] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [initStat, setInitStat] = useState(true);
  const [statusError, setStatusError] = useState(false);
  const [correlationId, setCorrelationId] = useState();
  const ref = useRef();

  useEffect(() => {
    if (search === true) {
      update(Checked, filtername);
      onClickClose();
    }
  }, [search]);
  // update function is called when there is any change in the search button in the dropdown

  useEffect(() => {
    retrieveStatus();
  }, []); // Api response for the status filter

  useLayoutEffect(() => {
    if (sitsContext.pageRedirection && (sitsContext.currentStatePageName !== 'dashboard')) {
      setTimeout(() => {
        setNewarray(sitsContext.previousStateStatusFilter);
        setChecked(sitsContext.previousStateStatusFilterShortName);
        setFiltername(sitsContext.previousStateStatusFilter);
        if ((sitsContext.previousStateStatusFilter.length !== 0)
        || (sitsContext.previousStateStatusFilterShortName.length !== 0)) {
          const filtervalues = {
            selectedfilters: sitsContext.previousStateStatusFilterShortName,
            selectedfiltersName: sitsContext.previousStateStatusFilter,
            searchselected: true,
          };
          props.StatusFilter(filtervalues);
        }
      }, 500);
    } else if (sitsContext.currentStatePageName === 'dashboard') {
      setTimeout(() => {
        setNewarray(sitsContext.startPagepreviousStateStatusFilter);
        setChecked(sitsContext.startPagepreviousStateStatusFilterShortName);
        setFiltername(sitsContext.startPagepreviousStateStatusFilter);
        if ((sitsContext.startPagepreviousStateStatusFilter.length !== 0)
        || (sitsContext.startPagepreviousStateStatusFilterShortName.length !== 0)) {
          const filtervalues = {
            selectedfilters: sitsContext.startPagepreviousStateStatusFilterShortName,
            selectedfiltersName: sitsContext.startPagepreviousStateStatusFilter,
            searchselected: true,
          };
          sitsContext.startPageupdatePreviousStatusFilter(
            sitsContext.startPagepreviousStateStatusFilter,
            sitsContext.startPagepreviousStateStatusFilterShortName,
          );
          props.StatusFilter(filtervalues);
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (typeof props.statCount !== 'undefined') {
      setInitStat(false);
      gStatusTypeValues.forEach((statusType) => {
        if (statusType.itemCount) delete statusType.itemCount;
        props.statCount.forEach((statusCount) => {
          if (
            statusCount.groupName.toUpperCase()
            === statusType.t_status.toUpperCase()
          ) {
            statusType.itemCount = statusCount.itemCount;
          }
        });
      });
      if (sitsContext.currentStatePageName === 'dashboard' && gStatusTypeValues.length === 5) {
        const splicingfilters = gStatusTypeValues;
        splicingfilters.splice(4, 1);
        dropdownStatusvalues = splicingfilters;
      } else {
        dropdownStatusvalues = gStatusTypeValues;
      }
    }
  }, [props.statCount]);

  useEffect(() => {
    if (props.filterarray !== undefined) {
      if (props.filterarray.length === 1 && props.filterarray[0] === 'Overdue') {
        update([], [], [], []);
      } else if (sitsContext.filterDisplayFlag
      && (props.filterarray !== null || props.filtervaluearray !== null)) {
        update(Checked, filtername, props.filterarray, props.filtervaluearray);
      }
    }
  }, [props.filterarray, props.filtervaluearray]);
  // update function is called when there is any change in the selected filters

  // Scroll dashboard page to view the Status filter body (Full section in the visible region)
  useEffect(() => {
    if (isModalOpen) {
      const filterBodyElement = document.getElementById('statusTypeFilterBody');
      if (filterBodyElement !== undefined && (filterBodyElement !== null)) {
        const filterBodyPositionValues = filterBodyElement.getBoundingClientRect();
        if (filterBodyPositionValues.bottom > (window.innerHeight)) {
          filterBodyElement.scrollIntoView(false);
        }
      }
    }
  }, [isModalOpen]);

  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
    if (gStatusTypeValues.length === 0 && gStatusTypeValuesError.length !== 0) {
      setStatusError(!statusError);
      setCorrelationId(gStatusTypeValuesError.data.CorrelationID);
    }
  };

  const onClickClose = () => {
    setStatusError(false);
    setModalOpen(false);
    setSelect(false);
  };

  const onClickSearch = () => {
    setSearch(true);
    sitsContext.updateEnableSearchbuttoninfilter(true);
  };

  useOnClickOutside(ref, () => {
    setStatusError(false);
    setModalOpen(false);
    setSelect(false);
  });

  const handleChange = (e) => {
    sitsContext.updateInitialFilterFlag(true);
    const selectedvalue = e.target.value;
    const selectedfilter = e.target.name;
    sitsContext.updateFilterValues(selectedfilter);
    const currentIndex2 = Checked.indexOf(selectedvalue);
    const newChecked = [...Checked];
    const currentIndex1 = filtername.indexOf(selectedfilter);
    const newFilterName = [...filtername];

    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newFilterName.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newFilterName.splice(currentIndex1, 1);
    }
    setChecked(newChecked);
    setFiltername(newFilterName);
    update(newChecked, newFilterName);
  };

  const update = (
    newChecked,
    newFilterName,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    if (cancelledarray === null) {
      setNewarray([]); // array is set to null if the clear all is clicked
    }
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : newChecked.filter((item) => cancelledarrayvalue.includes(item)),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newFilterName
          : newFilterName.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    setNewarray(filtervalues.selectedfiltersName);
    // array is updated with new values
    if (sitsContext.currentStatePageName !== 'dashboard') {
      sitsContext.updatePreviousStatusFilter(
        filtervalues.selectedfiltersName,
        filtervalues.selectedfilters,
      );
    } else if (sitsContext.currentStatePageName === 'dashboard') {
      sitsContext.startPageupdatePreviousStatusFilter(
        filtervalues.selectedfiltersName,
        filtervalues.selectedfilters,
      );
    }
    sitsContext.updateintialloading(true);
    props.StatusFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            // disabled={selectedPage === 'Dashboard' ? true : false}
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForStatusfilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv" id="statusTypeFilterBody">
            <div>
              <ActionList className="action-lists Status_dropdown" id="Status_Filters" variant="compact">
                {dropdownStatusvalues.map((Stat, index) => (
                  <ActionListItem
                    key={index}
                    control="checkbox"
                    controlProps={{
                      value: Stat.t_status,
                      name: Stat.t_status,
                      checked:
                        newarray.includes(Stat.t_status) === true,
                    }}
                    label={Stat.t_status}
                    onChange={handleChange}
                    quantityLabel={
                      Stat.itemCount === undefined ? 0 : spaceAfterhundreds(Stat.itemCount)
                    }
                    disabled={
                      (Stat.itemCount === undefined && !initStat)
                    }
                    className={initStat ? 'count-zero' : 'count-list'}
                  />
                ))}
              </ActionList>
            </div>
            <div>
              {(sitsContext.currentStatePageName !== 'dashboard' && (SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0)) && (
                <hr
                  className="divider"
                  style={{ border: '1px solid #dfdfdf' }}
                />
              )}
              {(sitsContext.currentStatePageName !== 'dashboard' && (SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0)) && (
                <div className="objectdiv filter_button">
                  <Button
                    className="status_userid_filter"
                    onClick={onClickClose}
                    small
                    text={lablesAndnames.button_closeButtonText}
                  />
                  <Button
                    className="status_userid_filter"
                    small
                    text={lablesAndnames.button_searchButtonText}
                    onClick={onClickSearch}
                    type="primary"
                    disabled={Checked.length === 0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {statusError && (<ErrorHandler errorDisplayed={4} correlationIdForFilter={correlationId} />)}
    </div>
  );
}
export default Statuscomponent;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
