import { ENV_NAME } from '../Config';
import { appInsights } from './ApplicationInsights';

const dataLogging = (data) => {
  appInsights.context.user.id = data.userId;
  appInsights.trackTrace({
    message: data.message,
    severityLevel: 1,
    properties: {
      User: data.userId,
      Locale_Selected: data.selectedLocale,
      AD_Groups: data.ad_groups,
      System: 'SITS UI',
      Env: ENV_NAME,
    },
  });
};

const errorLogging = (data) => {
  appInsights.trackException({
    severityLevel: 1,
    properties: {
      Message: data.message,
      User: window.sessionStorage.getItem('userId'),
      Locale_Selected: data.selectedLocale,
      APIName: data.apiName,
      Payload: data.apiPayload,
      Response: data.apiResponse,
      System: 'SITS UI',
      Env: ENV_NAME,
    },
  });
};

export {
  // eslint-disable-next-line import/prefer-default-export
  dataLogging,
  errorLogging,
};
