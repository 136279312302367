/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import React, {
  useState, useEffect, useRef, useContext, useLayoutEffect,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import Button from '@ingka/button';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import { SearchbarData } from '../searchBar/SearchBarComponent';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';
import dateFilterData from '../data/dateFilterData';
import { spaceAfterhundreds } from '../../../constants';

let dropdownForUpdatedBetweenFilter = [];

function UpdatedBetweenfiltercomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  // intailizing checked array to capture the checked(selected) filters
  const [filtername, setFiltername] = useState([]);
  // intailizing checked array to capture the selected filters name
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  // intializing the search to capture the search button function
  const [select, setSelect] = useState(false);
  // intializing the select to make the filter as selected
  const [isModalOpen, setModalOpen] = useState(false);
  // intializing to capture th correlation Id from filter api fail
  const [initStat, setInitStat] = useState(true);
  const [dateFiltervalues, setDateFiltersvalues] = useState(dateFilterData());
  const ref = useRef(); // intializing the use Ref hook to capture the click outside the Modal

  useEffect(() => {
    if (search === true) {
      update(Checked, filtername);
      onClickClose();
    }
  }, [search]);
  // update function is called when there is any change in the search button in the dropdown

  useLayoutEffect(() => {
    if (sitsContext.pageRedirection) {
      setTimeout(() => {
        setNewarray(sitsContext.previousStateDateFilter);
        setChecked(sitsContext.previousStateDateFilter);
        setFiltername(sitsContext.previousStateDateFilter);
        if ((sitsContext.previousStateDateFilter.length !== 0)
        || (sitsContext.previousStateDateFilter.length !== 0)) {
          const filtervalues = {
            selectedfilters: sitsContext.previousStateDateFilter,
            selectedfiltersName: sitsContext.previousStateDateFilter,
            searchselected: true,
          };
          props.updatedBetweenFilter(filtervalues);
        }
      }, 1000);
    }
  }, []);

  useLayoutEffect(() => {
    if (typeof props.updateBetweenFilterCount !== 'undefined') {
      setInitStat(false);
      dateFiltervalues.forEach((dateFilter) => {
        if (dateFilter.itemCount) delete (dateFilter.itemCount);
        props.updateBetweenFilterCount.forEach((UbFilterCount) => {
          if (
            UbFilterCount.groupName.toUpperCase()
            === dateFilter.dateFilter_name.toUpperCase()
          ) {
            dateFilter.itemCount = UbFilterCount.itemCount;
          }
        });
      });
      dropdownForUpdatedBetweenFilter = dateFiltervalues;
    }
  }, [props.updateBetweenFilterCount]);

  useEffect(() => {
    setDateFiltersvalues(dateFilterData());
  }, [sitsContext.previousStateDateFilter]);

  useEffect(() => {
    if ((sitsContext.filterDisplayFlag)
      && (props.filterarray !== null || props.filtervaluearray !== null)) {
      update(Checked, filtername, props.filterarray, props.filtervaluearray);
    }
  }, [props.filterarray, props.filtervaluearray]);
  // update function is called when there is any change in the selected filters

  // Scroll dashboard page to view the updated between filter body in the visible region
  useEffect(() => {
    if (isModalOpen) {
      const filterBodyElement = document.getElementById('updatedBetweenTypeFilterBody');
      if (filterBodyElement !== undefined && (filterBodyElement !== null)) {
        const filterBodyPositionValues = filterBodyElement.getBoundingClientRect();
        if (filterBodyPositionValues.bottom > (window.innerHeight)) {
          filterBodyElement.scrollIntoView(false);
        }
      }
    }
  }, [isModalOpen]);

  // on click functionality  for the Pill
  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
  };

  // close button functionality
  const onClickClose = () => {
    setModalOpen(false);
    setSelect(false);
  };

  // search button functionality
  const onClickSearch = () => {
    sitsContext.updateEnableSearchbuttoninfilter(true);
    setSearch(true);
  };

  // on clickoutside functinality using the useRef hook
  useOnClickOutside(ref, () => {
    setModalOpen(false);
    setSelect(false);
  });

  const handleChange = (e) => {
    sitsContext.updateInitialFilterFlag(true);
    const selectedvalue = e.target.value;
    const selectedfilter = e.target.name;
    sitsContext.updateFilterValues(selectedfilter);
    const currentIndex2 = Checked.indexOf(selectedvalue);
    // capturing the index value of target value
    const newChecked = [...Checked]; // the new checked array is appended with previous values
    const currentIndex1 = filtername.indexOf(selectedfilter);
    // capturing the index value of target name
    const newFilterName = [...filtername]; // the filtername array is appended with previous values

    // checking the index is -1 , if the index is -1 the value will be  pushed into the array
    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newFilterName.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newFilterName.splice(currentIndex1, 1);
    } // if the index is not  -1 the value will be spliced from the array
    setChecked(newChecked); // array is updated with new values
    setFiltername(newFilterName); // array is updated with new values
    update(newChecked, newFilterName);
  };

  const update = (
    newChecked,
    newFilterName,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    if (cancelledarray === null) {
      setNewarray([]); // array is set to null if the clear all is clicked
    }
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : newChecked.filter((item) => cancelledarrayvalue.includes(item)),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newFilterName
          : newFilterName.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    setNewarray(filtervalues.selectedfiltersName);
    // array is updated with new values
    sitsContext.updatePreviousDateFilter(
      filtervalues.selectedfiltersName,
      filtervalues.selectedfilters,
    );
    props.updatedBetweenFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForDatefilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv" id="updatedBetweenTypeFilterBody">
            <div>
              <ActionList className="action-lists updatedBetween_dropdown" id="date_filters" variant="compact">
                {dropdownForUpdatedBetweenFilter.map((dateValue, index) => (
                  <ActionListItem
                    key={index}
                    control="checkbox"
                    controlProps={{
                      value: dateValue.dateFilter_name,
                      name: dateValue.dateFilter_name,
                      checked:
                        newarray.includes(dateValue.dateFilter_name) === true,
                    }}
                    label={dateValue.dateFilter_name}
                    onChange={handleChange}
                    quantityLabel={
                       spaceAfterhundreds(dateValue.itemCount)
                    }
                    disabled={
                      !!(dateValue.itemCount === 0 && !initStat)
                    }
                    className={initStat ? 'count-zero' : 'count-list'}
                  />
                ))}
              </ActionList>
            </div>

            <div>
              {(SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0) && (
                <hr className="divider" />
              )}
              {(SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0) && (
                <div className="objectdiv filter_button">
                  <Button
                    className="status_userid_filter"
                    onClick={onClickClose}
                    small
                    text={lablesAndnames.button_closeButtonText}
                  />
                  <Button
                    className="status_userid_filter"
                    small
                    text={lablesAndnames.button_searchButtonText}
                    onClick={onClickSearch}
                    type="primary"
                    disabled={Checked.length === 0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default UpdatedBetweenfiltercomponent;

// outside click functionality when the click event is captured outside the Modal
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
