/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect } from 'react';
import './ToastMessage.scss';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/toast/style.scss';
import Toast from '@ingka/toast';
import SitsContext from '../../context/SitsContext';

export default function ToastMessage() {
  const sitsContext = useContext(SitsContext);

  useEffect(() => {
    if (sitsContext.toastMessage.showToastMessage === true) {
      setTimeout(() => {
        sitsContext.updateToastMessage(false, sitsContext.toastMessage.toastMessageDescription);
      }, 5000);
    }
  }, [sitsContext.toastMessage]);

  const hideToastMessageHandler = (value) => {
    sitsContext.updateToastMessage(false, value);
  };

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      <Toast
        id="toastMessageContainer"
        className={sitsContext.toastMessage.showToastMessage
          ? (sitsContext.toastMessage.toastMessageDescription.includes('loaded successfully')
            ? 'toast-display-locale-start'
            : 'toast-display')
          : (sitsContext.toastMessage.toastMessageDescription.includes('loaded successfully')
            ? 'toast-display-locale-exit'
            : 'toast-display-exit')}
        text={sitsContext.toastMessage.toastMessageDescription}
        onCloseRequest={() => hideToastMessageHandler(sitsContext.toastMessage.toastMessageDescription)}
        isOpen={sitsContext.toastMessage.showToastMessage}
      />

    </div>
  );
}
