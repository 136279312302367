/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import React, {
  useState, useEffect, useRef, useContext, useLayoutEffect,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import Button from '@ingka/button';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import { SearchbarData } from '../searchBar/SearchBarComponent';
import { gUserIdTypeValues, gUserIdTypeValuesError, retrieveUserId } from '../../../api/Apirequests';
import { selectedPopupValue } from '../../../App';
import ErrorHandler from '../../../common-components/errorhandler/ErrorHandler';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';
import { spaceAfterhundreds } from '../../../constants';

function Useridfiltercomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  // intailizing checked array to capture the checked(selected) filters
  const [filtername, setFiltername] = useState([]);
  // intailizing checked array to capture the selected filters name
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  // intializing the search to capture the search button function
  const [select, setSelect] = useState(false);
  // intializing the select to make the filter as selected
  const [isModalOpen, setModalOpen] = useState(false);
  // intializing the modalopen to display the dropdown of filters
  const [userIdError, setUserIdError] = useState(false);
  const [correlationId, setCorrelationId] = useState();
  const [initStat, setInitStat] = useState(true);
  const ref = useRef(); // intializing the use Ref hook to capture the click outside the Modal

  useEffect(() => {
    if (search === true) {
      update(Checked, filtername);
      onClickClose();
    }
  }, [search]);
  // update function is called when there is any change in the search button in the dropdown

  useEffect(() => {
    if (selectedPopupValue.selectedlangvalue !== undefined) {
      const checked = [];
      const Filtername = [];
      setChecked(checked);
      setFiltername(Filtername);
      update(checked, Filtername);
      retrieveUserId(selectedPopupValue.selectedlangvalue);
    }
  }, [selectedPopupValue.selectedlangvalue]); // Api response for the Objecttype filter

  useLayoutEffect(() => {
    if (sitsContext.pageRedirection) {
      setTimeout(() => {
        setNewarray(sitsContext.previousStateUseridFilter);
        setChecked(sitsContext.previousStateUseridFilterShortName);
        setFiltername(sitsContext.previousStateUseridFilter);
        if ((sitsContext.previousStateUseridFilter.length !== 0)
        || (sitsContext.previousStateUseridFilterShortName.length !== 0)) {
          const filtervalues = {
            selectedfilters: sitsContext.previousStateUseridFilterShortName,
            selectedfiltersName: sitsContext.previousStateUseridFilter,
            searchselected: true,
          };
          props.UseridFilter(filtervalues);
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (typeof props.userIdCount !== 'undefined') {
      setInitStat(false);
      gUserIdTypeValues.forEach((user) => {
        if (user.itemCount) delete (user.itemCount);
        props.userIdCount.forEach((useridCount) => {
          if (
            useridCount.groupName.toUpperCase()
            === user.user_Id.toUpperCase()
          ) {
            user.itemCount = useridCount.itemCount;
          }
        });
      });
    }
  }, [props.userIdCount, gUserIdTypeValues]);

  useEffect(() => {
    if ((sitsContext.filterDisplayFlag)
      && (props.filterarray !== null || props.filtervaluearray !== null)) {
      update(Checked, filtername, props.filterarray, props.filtervaluearray);
    }
  }, [props.filterarray, props.filtervaluearray]);
  // update function is called when there is any change in the selected filters

  // Scroll the dashboard page to view the user id filter body (Full section in the visible region)
  useEffect(() => {
    if (isModalOpen) {
      const filterBodyElement = document.getElementById('userIdTypeFilterBody');
      if (filterBodyElement !== undefined && (filterBodyElement !== null)) {
        const filterBodyPositionValues = filterBodyElement.getBoundingClientRect();
        if (filterBodyPositionValues.bottom > (window.innerHeight)) {
          filterBodyElement.scrollIntoView(false);
        }
      }
    }
  }, [isModalOpen]);

  // on click functionality  for the Pill
  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
    if (gUserIdTypeValues.length === 0 && gUserIdTypeValuesError.length !== 0) {
      setUserIdError(!userIdError);
      setCorrelationId(gUserIdTypeValuesError.data.CorrelationID);
    }
  };

  // close button functionality
  const onClickClose = () => {
    setUserIdError(false);
    setModalOpen(false);
    setSelect(false);
  };

  // search button functionality
  const onClickSearch = () => {
    sitsContext.updateEnableSearchbuttoninfilter(true);
    setSearch(true);
  };

  // on clickoutside functinality using the useRef hook
  useOnClickOutside(ref, () => {
    setUserIdError(false);
    setModalOpen(false);
    setSelect(false);
  });

  const handleChange = (e) => {
    sitsContext.updateInitialFilterFlag(true);
    const selectedvalue = e.target.value;
    const selectedfilter = e.target.name;
    sitsContext.updateFilterValues(selectedfilter);
    const currentIndex2 = Checked.indexOf(selectedvalue);
    // capturing the index value of target value
    const newChecked = [...Checked]; // the new checked array is appended with previous values
    const currentIndex1 = filtername.indexOf(selectedfilter);
    // capturing the index value of target name
    const newFilterName = [...filtername]; // the filtername array is appended with previous values

    // checking the index is -1 , if the index is -1 the value will be  pushed into the array
    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newFilterName.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newFilterName.splice(currentIndex1, 1);
    } // if the index is not  -1 the value will be spliced from the array
    setChecked(newChecked); // array is updated with new values
    setFiltername(newFilterName); // array is updated with new values
    update(newChecked, newFilterName);
  };

  const update = (
    newChecked,
    newFilterName,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    if (cancelledarray === null) {
      setNewarray([]); // array is set to null if the clear all is clicked
    }
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : newChecked.filter((item) => cancelledarrayvalue.includes(item)),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newFilterName
          : newFilterName.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    setNewarray(filtervalues.selectedfiltersName);
    // array is updated with new values
    sitsContext.updatePreviousUseridFilter(
      filtervalues.selectedfiltersName,
      filtervalues.selectedfilters,
    );
    props.UseridFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForUserIdfilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv" id="userIdTypeFilterBody">
            <div>
              <ActionList className="action-lists Userid_dropdown" id="Userid_filters" variant="compact">
                {gUserIdTypeValues.map((user, index) => (
                  <ActionListItem
                    key={index}
                    control="checkbox"
                    controlProps={{
                      value: user.user_Id,
                      name: user.user_Id,
                      checked:
                        newarray.includes(user.user_Id) === true,
                    }}
                    label={user.user_Id}
                    onChange={handleChange}
                    quantityLabel={
                      user.itemCount === undefined
                        ? 0 : spaceAfterhundreds(user.itemCount)
                    }
                    disabled={
                      !!(user.itemCount === undefined && !initStat)
                    }
                    className={initStat ? 'count-zero' : 'count-list'}
                  />
                ))}
              </ActionList>
            </div>

            <div>
              {(SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0) && (
                <hr className="divider" />
              )}
              {(SearchbarData.search_value === null
                || SearchbarData.search_value === undefined)
                && !((sitsContext.previousStateCount > 0
                || sitsContext.previousStateCount !== undefined)
                && sitsContext.previousStateSelectedFilter.selectedFiltersName.length > 0) && (
                <div className="objectdiv filter_button">
                  <Button
                    className="status_userid_filter"
                    onClick={onClickClose}
                    small
                    text={lablesAndnames.button_closeButtonText}
                  />
                  <Button
                    className="status_userid_filter"
                    small
                    text={lablesAndnames.button_searchButtonText}
                    onClick={onClickSearch}
                    type="primary"
                    disabled={Checked.length === 0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {userIdError && (<ErrorHandler errorDisplayed={4} correlationIdForFilter={correlationId} />)}
    </div>
  );
}
export default Useridfiltercomponent;

// outside click functionality when the click event is captured outside the Modal
const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
